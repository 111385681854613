/* eslint-disable no-useless-escape */
/* eslint-disable check-file/filename-naming-convention */
import dayjs from "dayjs";

import { Couple, Family, Group, Individual } from "@/config/app-constant";
import {
  PRODUCT,
  SECONDARY_PRODUCT,
  MEDICAL_FLOW_V2_ENABLED,
  MAX_ADDRESS_LENGTH,
} from "@/constants";
import { AddressType } from "@/types/AddressTypes";
import { FormDataTypeOrganiser } from "@/types/OrganiserType";
import { Organiser, Traveller } from "@/types/QuestionPayloadType";
import { FormDataType } from "@/types/TravellerType";

import { OrganiserContent } from "./schema/organiser-page-schema.utils";
import { isValidDate, stripTimeFromDate } from "./travel-date.utils";
import { validateName } from "./traveller.utils";

const ageLimit = {
  avanti: { min: 18, max: 120 },
  staysure: { min: 18, max: 120 },
  expat: { min: 18, max: 85 },
};

const brandImpact = {
  avanti: false,
  staysure: false,
  expat: true,
};

const sendTargetCountry = {
  avanti: false,
  staysure: false,
  expat: true,
};

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

export const showNationalityFieldInExpat = brandImpact[brand];
export const ageLimitOrganizer = ageLimit[brand];
export const sendTargetCountryToAddressService = sendTargetCountry[brand];
export const allowTownFieldInAddress = brandImpact[brand];

export function getOrganiserDateValidation(
  form: FormDataType,
  travellerType: string,
  dobMaxLimit: string,
  dobMinAge: string,
  dobMaxAge: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let errorMessage = "";
  if (form.value4) {
    if (Number(form.value4) < 0 || Number(form.value4) === -0.5) {
      errorMessage = dobMinAge;
    } else if (Number(form.value4) > ageLimitOrganizer.max) {
      errorMessage = dobMaxAge;
    } else if (Number(form.value4) < ageLimitOrganizer.min) {
      errorMessage = dobMaxLimit;
    }
  }
  if (!isValidDate(form.dateValue as Date)) {
    errorMessage = "Invalid Date of Birth";
  }

  // else if (travellerType === Family) {
  //   if (Number(form.value4) > 17 && Number(form.value4) < 21) {
  //     if (form.fullTimeEducation) {
  //       errorMessage =
  //         ageFamilyFullTimeEducation ||
  //         "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education";
  //     }
  //   }
  // } else if (travellerType === SingleParentFamily) {
  //   if (Number(form.value4) > 17 && Number(form.value4) < 21) {
  //     if (form.fullTimeEducation) {
  //       errorMessage =
  //         ageSingleParentFullTimeEducation ||
  //         "Single Parent travel  must be 1 adults plus any number of children under 18, or under 21 if in full time education";
  //     }
  //   }
  // }
  return errorMessage;
}

export function getOrganiserDateValidationStates(form: FormDataType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let errorState = false;
  if (form.dateValue && isValidDate(form.dateValue) && form.value4) {
    if (Number(form.value4) < 0) {
      errorState = true;
    } else if (Number(form.value4) > ageLimitOrganizer.max) {
      // AGE VALIDATOR
      errorState = true;
    } else if (Number(form.value4) < ageLimitOrganizer.min) {
      errorState = true;
    }

    // else if (travellerType === Family) {
    //   if (Number(form.value4) > 17 && Number(form.value4) < 21) {
    //     if (form.fullTimeEducation) {
    //       errorState = true;
    //     }
    //   }
    // } else if (travellerType === SingleParentFamily) {
    //   if (Number(form.value4) < 18) {
    //     errorState = true;
    //   } else if (Number(form.value4) > 17 && Number(form.value4) < 21) {
    //     if (form.fullTimeEducation) {
    //       errorState = true;
    //     }
    //   }
    // }
  }
  return errorState;
}

export function validateDateOrganiser(
  form: FormDataType,
  travellerType: string,
  dobMinAge?: string,
  dobMaxAge?: string,
  dobMaxLimit?: string
) {
  const errors: string[] = [];

  if (form.dateValue && isValidDate(form.dateValue)) {
    const date2 = form.dateValue && stripTimeFromDate(form.dateValue);
    const birthDate = dayjs(date2);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, "year");

    if (age < 0) {
      errors.push(dobMinAge || "");
    }
    if (age > ageLimitOrganizer.max) {
      errors.push(dobMaxAge as string);
    }

    const enterdAge = Number(form.value4);
    if (enterdAge < 0) {
      errors.push(dobMinAge || "");
    }
    if (enterdAge < ageLimitOrganizer.min) {
      errors.push(dobMaxLimit || "");
    }
    if (enterdAge > ageLimitOrganizer.max) {
      errors.push(dobMaxAge || "");
    }
  } else {
    errors.push("Invalid Date of Birth");
  }

  // if (travellerType === Family || travellerType === SingleParentFamily) {
  //   const errorMsgFamily =
  //     ageFamilyFullTimeEducation ||
  //     "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education";
  //   const errorMsgFamilySingle =
  //     ageSingleParentFullTimeEducation ||
  //     "A family must be 1 adults plus any number of children under 18, or under 21 if in full time education";

  //   const errorMsg =
  //     travellerType === Family ? errorMsgFamily : errorMsgFamilySingle;
  //   if (form.fullTimeEducation) {
  //     errors.push(errorMsg);
  //   }
  // }

  return errors;
}

// Regex for input fields
const inputAddressRegex =
  /^([-''()_,.|`'’"º°ª\&\s]*[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff0-9\u1E00-\u1EFF]+)+([-''()_,./`'’"º°ª\&\s]*[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff0-9\u1E00-\u1EFF]*)*$/;

const inputTownRegex =
  /^([-''()_,.|`'’\\"\\&]*\s*)*[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\u1E00-\u1EFF0-9]+((\s)*[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\u1E00-\u1EFF0-9-''()_,./`'’|\\"\\&]*)*$/;

const inputPostCodeRegex = (countryFrom: string) => {
  switch (countryFrom) {
    case "GBR":
    case "GB":
      return /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}$/;
    case "FRA":
    case "FR":
      return /^\d{5}$/;
    case "ESP":
    case "ES":
      return /^\d{5}$/;
    case "PRT":
    case "PT":
      return /^(\d{4} \d{3}|\d{4}|\d{7})$/;
    default:
      return /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}$/;
  }
};

export const regexValidaton = (input: string, regex: RegExp) => {
  return !regex.test(input);
};

export const inputCharacterCount = (input: string, length: number) =>
  input.length > length;

export function isFormDataCompleteOrganiser(
  formData: FormDataTypeOrganiser,
  travellerType?: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: any[] = [];

  if (
    MEDICAL_FLOW_V2_ENABLED === "true" &&
    travellerType === Individual &&
    formData.travelling &&
    formData.hasMedicalConditions !== false
  ) {
    return false;
  }

  if (
    !formData.value1 ||
    !formData.value2 ||
    !formData.value3 ||
    (!formData.value4 &&
      (formData.dateValue === undefined || !formData.dateValue)) ||
    (showNationalityFieldInExpat && !formData.nationalityValue)
  ) {
    return false;
    // eslint-disable-next-line no-else-return
  }

  if (
    formData.travelling === undefined ||
    !formData.postCode ||
    !formData.firstLineOfAddress ||
    !formData.city ||
    !formData.country
  ) {
    return false;
    // eslint-disable-next-line no-else-return
  }

  if (formData.dateValue === undefined) {
    return false;
  }

  if (formData.value2) {
    const error1 = validateName(formData.value2);
    error = [...error, ...error1];
  }
  if (formData.value3) {
    const error1 = validateName(formData.value3);
    error = [...error, ...error1];
  }
  if (formData.value4) {
    const error1 = validateDateOrganiser(formData, travellerType || Individual);
    error = [...error, ...error1];
  }

  if (!formData.firstLineOfAddress) return false;
  if (regexValidaton(formData.firstLineOfAddress, inputAddressRegex)) {
    return false;
  }

  if (
    formData.secondLineOfAddress &&
    regexValidaton(formData.secondLineOfAddress, inputAddressRegex)
  ) {
    return false;
  }

  if (!formData.city) return false;
  if (regexValidaton(formData.city, inputTownRegex)) {
    return false;
  }

  if (!formData.postCode) return false;
  if (
    regexValidaton(formData.postCode, inputPostCodeRegex(formData.country)) ||
    inputCharacterCount(formData.postCode, 8)
  ) {
    return false;
  }

  if (error.length > 0) {
    return false;
  }

  return true;
}

// Validation for input fields
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkValidation = (
  input: string,
  countryFrom: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
) => {
  switch (input) {
    case "line1":
      return [
        {
          errorType: "regex",
          validation: inputAddressRegex,
          errorMsg: props?.addressLineOneErrorMasg ?? "Input Error",
        },
        {
          errorType: "maxTextCount",
          validation: MAX_ADDRESS_LENGTH,
          errorMsg: props?.addressLineOneErrorMasg ?? "Input Error",
        },
      ];
    case "line2":
      return [
        {
          errorType: "regex",
          validation: inputAddressRegex,
          errorMsg: props?.addressLineTwoErrorMasg ?? "Input Error",
        },
        {
          errorType: "maxTextCount",
          validation: MAX_ADDRESS_LENGTH,
          errorMsg: props?.addressLineTwoErrorMasg ?? "Input Error",
        },
      ];
    case "city":
      return [
        {
          errorType: "regex",
          validation: inputTownRegex,
          errorMsg: props?.TownCityErrorMasg ?? "Input Error",
        },
        {
          errorType: "maxTextCount",
          validation: 40,
          errorMsg: props?.TownCityErrorMasg ?? "Input Error",
        },
      ];
    case "postalCode":
      return [
        {
          errorType: "regex",
          validation: inputPostCodeRegex(countryFrom),
          errorMsg: props?.postcodeErrorMasg ?? "Input Error",
        },
        {
          errorType: "maxTextCount",
          validation: 8,
          errorMsg: props?.postcodeErrorMasg ?? "Input Error",
        },
      ];
    default:
      return [];
  }
};

export const ifAddressData = (addressData: AddressType[]) => {
  if (
    addressData.length > 0 &&
    addressData[0]?.attributes &&
    addressData[0].attributes.line1 &&
    (addressData[0].attributes.city || allowTownFieldInAddress) && // Some address in EXP doesnt have town
    addressData[0].attributes.countryIso2 &&
    addressData[0].attributes.postalCode
  ) {
    return true;
  }
  return false;
};

export function modifyOrganiserForm(organizer: Organiser) {
  const form: FormDataTypeOrganiser = {
    value1: organizer.title,
    value2: organizer.firstName,
    value3: organizer.lastName,
    // nationalityValue: organizer.nationality,
    nationalityValue: organizer.nationalityId,
    id: 1,
    dateValue: organizer.dateOfBirth
      ? new Date(organizer?.dateOfBirth)
      : undefined,
    isDateCalenter: true,
    value4: "",
    travelling: organizer.travelling,
    fullTimeEducation: organizer.fullTimeEducation,
    postCode: organizer.postCode,
    firstLineOfAddress: organizer.firstLineOfAddress,
    secondLineOfAddress: organizer.secondLineOfAddress,
    city: organizer.city,
    country: organizer.country,
    telephone: organizer?.telephone,
    email: organizer?.email,
  };

  if (organizer.dateOfBirth) {
    form.dateValue = new Date(organizer.dateOfBirth);
    form.isDateCalenter = true;
    const birthDate = dayjs(form.dateValue);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, "year");
    form.value4 = age.toString();
  }

  return form;
}

export const modifyOrganiserTraveller = (
  traveller: Traveller[],
  organiserTravellerId?: string
) => {
  let travellerEdit: Traveller[] = [...(traveller || [])];
  if (travellerEdit && travellerEdit.length > 0) {
    const travellingOrganiser = travellerEdit.find(
      (obj) => obj?.sequence === 1
    );

    if (
      organiserTravellerId === travellingOrganiser?.encryptedId ||
      !organiserTravellerId
    ) {
      sessionStorage.setItem(
        "organiserTravellerId",
        travellingOrganiser?.encryptedId || ""
      );
      if (travellerEdit && travellerEdit.length === 1) {
        travellerEdit = [];
      } else if (travellerEdit && travellerEdit.length > 1) {
        const indexToRemove = travellerEdit.findIndex(
          (obj) => obj?.sequence === 1
        );
        if (indexToRemove > -1) {
          // only splice array when item is found

          travellerEdit?.splice(indexToRemove, 1); // 2nd parameter means remove one item only
        }

        // eslint-disable-next-line no-param-reassign
      }
    }
  }
  return travellerEdit;
};

export function getSelectedSupportContent(
  selectedPartyType: string | number | undefined,
  props: OrganiserContent,
  isOrganiserTravelling: boolean | undefined,
  hasMedicalCondition?: boolean | undefined
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };
  const isIndividualOrganizerTravelling: boolean = !!(
    isOrganiserTravelling && selectedPartyType === Individual
  );

  if (isOrganiserTravelling === undefined) {
    supportSectionContent = {
      drawerContent: props.defaultSupportDrawer,
      drawerEnable: props.defaultLoadMoreEnable,
      rightContent: props.assistantDescription,
      loadMoreButtonText: props.defaultLoadMoreButtonText,
    };
  }
  if (isIndividualOrganizerTravelling) {
    if (MEDICAL_FLOW_V2_ENABLED !== "true") {
      supportSectionContent = {
        drawerContent: props.travellingYesSupportDrawer,
        drawerEnable: props.travellingYesLoadMoreEnable,
        rightContent: props.travellingIndividualYesSupportSection,
        loadMoreButtonText: props.travellingYesLoadMoreButtonText,
      };
    } else {
      if (hasMedicalCondition === undefined) {
        supportSectionContent = {
          drawerContent: props.travellingYesSupportDrawer,
          drawerEnable: props.travellingYesLoadMoreEnable,
          rightContent: props.travellingIndividualYesSupportSection,
          loadMoreButtonText: props.travellingYesLoadMoreButtonText,
        };
      }
      if (hasMedicalCondition === true) {
        supportSectionContent = {
          drawerContent: props.withMedicalConditionSupportDrawer,
          drawerEnable: props.withMedicalConditionLoadMoreEnable,
          rightContent: props.withOrganiserMedicalConditionSupportSection,
          loadMoreButtonText: props.withMedicalConditionLoadMoreButtonText,
        };
      }
      if (hasMedicalCondition === false) {
        supportSectionContent = {
          drawerContent: props.withoutMedicalConditionSupportDrawer,
          drawerEnable: props.withoutMedicalConditionLoadMoreEnable,
          rightContent: props.withoutMedicalConditionSupportSection,
          loadMoreButtonText: props.withoutMedicalConditionLoadMoreButtonText,
        };
      }
    }
  }

  if (isOrganiserTravelling === false && selectedPartyType === Individual) {
    supportSectionContent = {
      drawerContent: props.travellingNoSupportDrawer,
      drawerEnable: props.travellingNoLoadMoreEnable,
      rightContent: props.travellingIndividualNoSupportSection,
      loadMoreButtonText: props.travellingNoLoadMoreButtonText,
    };
  }

  if (isOrganiserTravelling && selectedPartyType === Couple) {
    supportSectionContent = {
      drawerContent: props.travellingYesSupportDrawer,
      drawerEnable: props.travellingYesLoadMoreEnable,
      rightContent: props.travellingCoupleYesSupportSection,
      loadMoreButtonText: props.travellingYesLoadMoreButtonText,
    };
  }

  if (isOrganiserTravelling === false && selectedPartyType === Couple) {
    supportSectionContent = {
      drawerContent: props.travellingNoSupportDrawer,
      drawerEnable: props.travellingNoLoadMoreEnable,
      rightContent: props.travellingCoupleNoSupportSection,
      loadMoreButtonText: props.travellingNoLoadMoreButtonText,
    };
  }

  if (
    isOrganiserTravelling &&
    (selectedPartyType === Family ||
      selectedPartyType === "SINGLE_PARENT_FAMILY")
  ) {
    supportSectionContent = {
      drawerContent: props.travellingYesSupportDrawer,
      drawerEnable: props.travellingYesLoadMoreEnable,
      rightContent: props.travellingFamilyYesSupportSection,
      loadMoreButtonText: props.travellingYesLoadMoreButtonText,
    };
  }

  if (
    isOrganiserTravelling === false &&
    (selectedPartyType === Family ||
      selectedPartyType === "SINGLE_PARENT_FAMILY")
  ) {
    supportSectionContent = {
      drawerContent: props.travellingNoSupportDrawer,
      drawerEnable: props.travellingNoLoadMoreEnable,
      rightContent: props.travellingFamilyNoSupportSection,
      loadMoreButtonText: props.travellingNoLoadMoreButtonText,
    };
  }

  if (isOrganiserTravelling && selectedPartyType === Group) {
    supportSectionContent = {
      drawerContent: props.travellingYesSupportDrawer,
      drawerEnable: props.travellingYesLoadMoreEnable,
      rightContent: props.travellingOtherYesSupportSection,
      loadMoreButtonText: props.travellingYesLoadMoreButtonText,
    };
  }

  if (isOrganiserTravelling === false && selectedPartyType === Group) {
    supportSectionContent = {
      drawerContent: props.travellingNoSupportDrawer,
      drawerEnable: props.travellingNoLoadMoreEnable,
      rightContent: props.travellingOtherNoSupportSection,
      loadMoreButtonText: props.travellingNoLoadMoreButtonText,
    };
  }
  return supportSectionContent;
}

const strokeConfig = [
  {
    brand: "staysure",
    strokeColorCode: "#272427",
  },
  {
    brand: "avanti",
    strokeColorCode: "#2E2D2C",
  },
];

export function getStrokeColor(lBrand: string | undefined): string {
  let strokeColor = "";
  strokeConfig.forEach((config) => {
    if (config.brand === lBrand) {
      strokeColor = `${config.strokeColorCode}`;
    }
  });
  return strokeColor;
}
