/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
import dayjs from "dayjs";

import {
  Couple,
  Family,
  Group,
  Individual,
  SingleParentFamily,
} from "@/config/app-constant";
import {
  MEDICAL_FLOW_V2_ENABLED,
  PRODUCT,
  SECONDARY_PRODUCT,
} from "@/constants";
import { Organiser, Traveller } from "@/types/QuestionPayloadType";
import { FormDataType } from "@/types/TravellerType";

// eslint-disable-next-line import/no-extraneous-dependencies
import { TravellersContent } from "./schema/travellers-page-schema.utils";
import { isValidDate, stripTimeFromDate } from "./travel-date.utils";
import { showNationalityFieldInExpat } from "./organiser.utils";

/* eslint-disable import/prefer-default-export */
export const titleData = [
  {
    id: "1",
    displayName: "Mr",
  },
  {
    id: "2",
    displayName: "Mrs",
  },
  {
    id: "3",
    displayName: "Miss",
  },
  {
    id: "4",
    displayName: "Ms",
  },
  {
    id: "5",
    displayName: "Dr",
  },
  {
    id: "6",
    displayName: "Rev",
  },
];

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

const ageLimit = {
  avanti: { min: 18, max: 120 },
  staysure: { min: 18, max: 120 },
  expat: { min: 18, max: 85 },
};

export const ageLimitOrganizer = ageLimit[brand];
type TitleKeys = "Mr" | "Mrs" | "Miss" | "Ms" | "Dr" | "Rev";
const genderMapping: Record<TitleKeys, string | null> = {
  Mr: "Male",
  Mrs: "Female",
  Miss: "Female",
  Ms: "Female",
  Dr: null,
  Rev: null,
};
export function getGenderByTitle(title: string) {
  const normalizedTitle = title.trim(); // Normalize input if needed
  if (normalizedTitle in genderMapping) {
    return genderMapping[normalizedTitle as TitleKeys];
  }
  return null;
}

export function validateName(
  value: string,
  inputErrorNumericvalues?: string,
  inputErrorSpecialCharacters?: string,
  inputErrorMaximumNumber?: string
) {
  const errors: string[] = [];
  // Validation logic for first name
  if (!value.trim()) {
    // If input is empty, clear error message
    errors.length = 0;
  } else {
    if (!/^[a-zA-Z\s'-]+$/.test(value)) {
      // Check if input contains only numeric values
      if (/^\d+$/.test(value)) {
        errors.push(inputErrorNumericvalues || "");
      } else {
        errors.push(inputErrorSpecialCharacters || "");
      }
    }
    if (value.length > 50) {
      errors.push(inputErrorMaximumNumber || "");
    }
  }
  return errors;
}

export function validateDate(
  form: FormDataType,
  travellerType: string,
  dobMinAge?: string,
  coupleMinAge?: string,
  dobMaxAge?: string
) {
  const errors: string[] = [];
  if ((form.dateValue && isValidDate(form.dateValue as Date)) || form.value4) {
    const date2 = form.dateValue && stripTimeFromDate(form.dateValue);
    const birthDate = dayjs(date2);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, "year");

    if (age < 0) {
      errors.push(dobMinAge || "");
    }
    if (age > ageLimitOrganizer.max) {
      errors.push(dobMaxAge as string);
    }

    const enterdAge = Number(form.value4);
    if (enterdAge < 0) {
      errors.push(dobMinAge || "");
    } else if (enterdAge < ageLimitOrganizer.min && travellerType === Couple) {
      errors.push(coupleMinAge || "");
    }
    if (enterdAge > ageLimitOrganizer.max) {
      errors.push(dobMaxAge || "");
    }
  } else if (form.dateValue) {
    errors.push("Invalid Date of Birth");
  }

  return errors;
}

export function validateDateGroup(
  form: FormDataType,
  formData: FormDataType[],
  dobMinAge?: string,
  dobMaxAge?: string
) {
  let errors: string[] = [];
  // eslint-disable-next-line array-callback-return
  formData.map((data) => {
    let errorStatus = false;
    if (
      (form.dateValue && isValidDate(form.dateValue as Date)) ||
      form.value4
    ) {
      const date2 = data.dateValue && stripTimeFromDate(data.dateValue);
      const birthDate = dayjs(date2);
      const currentDate = dayjs();
      const age = currentDate.diff(birthDate, "year");

      if (age < 0) {
        errors.push(dobMinAge || "");
        errorStatus = true;
      } else if (age > ageLimitOrganizer.max) {
        errors.push(dobMaxAge as string);
        errorStatus = true;
      }
      const enterdAge = Number(data.value4);
      if (enterdAge && !data.dateValue) {
        if (enterdAge < 0) {
          errors.push(dobMinAge || "");
          errorStatus = true;
        } else if (enterdAge > ageLimitOrganizer.max) {
          errors.push(dobMaxAge || "");
          errorStatus = true;
        }
      }
    } else if (form.dateValue || data.dateValue) {
      errorStatus = true;
    }

    if (!errorStatus) {
      errors.push("");
    }
  });
  let isEmpty = true;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < errors.length; i++) {
    if (errors[i] !== "") {
      isEmpty = false;
      break;
    }
  }
  if (isEmpty) {
    errors = [];
  }

  return errors;
}

export function getGroupValidate(
  formData: FormDataType[],
  form: FormDataType,
  ageGroupUnderTwo?: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: any[] = [];
  let ageParent = false;
  let underTwoAbove = false;
  let underTwoExsist = false;
  // eslint-disable-next-line no-plusplus
  if (formData) {
    // eslint-disable-next-line array-callback-return
    formData.map((data) => {
      if (data.value4 && Number(data.value4) > 17) {
        ageParent = true;
      }
      if (!ageParent && data.value4 && Number(data.value4) < 3) {
        underTwoExsist = true;
        error.push(
          ageGroupUnderTwo ||
            "Children under 2 must travel with an accompanying adult."
        );
      } else {
        error.push("");
      }
      if (data.value4 && Number(data.value4) > 2) {
        underTwoAbove = true;
      }
    });
    if (ageParent || (underTwoAbove && !underTwoExsist)) {
      error = [];
    }
  }

  return error;
}

export function getGroupValidate2(
  formData: FormDataType[],
  indexCheck: number,
  ageGroupUnderTwo?: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: any[] = [];
  let ageParent = false;
  // eslint-disable-next-line no-plusplus
  if (formData) {
    // eslint-disable-next-line array-callback-return
    formData.map((data, index) => {
      if (data.value4 && Number(data.value4) > 17) {
        ageParent = true;
      }

      if (
        !ageParent &&
        data.value4 &&
        Number(data.value4) < 3 &&
        index === indexCheck
      ) {
        error.push(
          ageGroupUnderTwo ||
            "Children under 2 must travel with an accompanying adult."
        );
      }
    });

    if (ageParent) {
      error = [];
    }
  }

  return error;
}

export function isFamilyValidate(
  index: number,
  formData: FormDataType,
  travelling: boolean,
  ageParentFamily?: string,
  ageFamilyFullTimeEducation?: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any[] = [];
  // eslint-disable-next-line no-plusplus
  if (formData && formData.value4) {
    if (
      (travelling && index === 0) ||
      (!travelling && (index === 0 || index === 1))
    ) {
      if (Number(formData.value4) < ageLimitOrganizer.min) {
        error.push(ageParentFamily || "Two person must be above 18");
      } else if (Number(formData.value4) > 17 && Number(formData.value4) < 21) {
        if (
          formData.fullTimeEducation ||
          formData.fullTimeEducation === undefined
        ) {
          error.push(
            ageFamilyFullTimeEducation ||
              "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education"
          );
        }
      }
    } else if ((!travelling && index > 1) || travelling) {
      if (Number(formData.value4) > 17 && Number(formData.value4) < 21) {
        if (
          formData.fullTimeEducation === false ||
          formData.fullTimeEducation === undefined
        ) {
          error.push(
            ageFamilyFullTimeEducation ||
              "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education"
          );
        }
      } else if (Number(formData.value4) > 20) {
        error.push(
          ageFamilyFullTimeEducation ||
            "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education"
        );
      }
    }
  }

  return error;
}

export function isSingleFamilyValidate(
  index: number,
  formData: FormDataType,
  travelling: boolean,
  ageSingleParentFamily?: string,
  ageSingleParentFullTimeEducation?: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any[] = [];
  // eslint-disable-next-line no-plusplus
  if (formData && formData.value4) {
    if (!travelling && index === 0) {
      if (Number(formData.value4) < ageLimitOrganizer.min) {
        error.push(ageSingleParentFamily || "One person must be above 18");
      } else if (Number(formData.value4) > 17 && Number(formData.value4) < 21) {
        if (
          formData.fullTimeEducation ||
          formData.fullTimeEducation === undefined
        ) {
          error.push(
            ageSingleParentFullTimeEducation ||
              "Single Parent travel  must be 1 adults plus any number of children under 18, or under 21 if in full time education"
          );
        }
      }
    } else if ((!travelling && index >= 1) || travelling) {
      if (Number(formData.value4) > 17 && Number(formData.value4) < 21) {
        if (
          formData.fullTimeEducation === false ||
          formData.fullTimeEducation === undefined
        ) {
          error.push(
            ageSingleParentFullTimeEducation ||
              "Single Parent travel must be 1 adults plus any number of children under 18, or under 21 if in full time education"
          );
        }
      } else if (Number(formData.value4) > 20) {
        error.push(
          ageSingleParentFullTimeEducation ||
            "Single Parent travel  must be 1 adults plus any number of children under 18, or under 21 if in full time education"
        );
      }
    }
  }

  return error;
}

export function isFormDataComplete(
  formData: FormDataType[],
  travellerType: string,
  travelling: boolean
) {
  // eslint-disable-next-line array-callback-return, consistent-return, no-plusplus
  for (let index = 0; index < formData.length; index++) {
    const data = formData[index];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let error: any[] = [];
    if (data.isDateCalenter === false) {
      if (
        !data.value1 ||
        !data.value2 ||
        !data.value3 ||
        !data.value4 ||
        (showNationalityFieldInExpat && !data.nationalityValue)
      ) {
        return false;
      }
    }
    if (
      !data.value1 ||
      !data.value2 ||
      !data.value3 ||
      (!data.value4 &&
        (data.dateValue === undefined ||
          !isValidDate(data.dateValue as Date))) ||
      (showNationalityFieldInExpat && !data.nationalityValue)
    ) {
      return false;
      // eslint-disable-next-line no-else-return
    }

    // if (data.dateValue === undefined && travellerType === Individual) {
    //   return false;
    // }
    if (data.value2) {
      const error1 = validateName(data.value2);
      error = [...error, ...error1];
    }
    if (data.value3) {
      const error1 = validateName(data.value3);
      error = [...error, ...error1];
    }
    if (data.value4) {
      const error1 = validateDate(data, travellerType);
      error = [...error, ...error1];
    }

    if (travellerType === Family) {
      const error1 = isFamilyValidate(index, data, travelling);
      error = [...error, ...error1];
    }

    if (travellerType === SingleParentFamily) {
      const error1 = isSingleFamilyValidate(index, data, travelling);
      error = [...error, ...error1];
    }

    if (travellerType === Group && !travelling) {
      const error1 = getGroupValidate(formData, data);
      error = [...error, ...error1];
    }

    if (error.length > 0) {
      return false;
    }
  }

  return true;
}

export function getTravellerForm(
  travellerType: string,
  travelling: boolean | undefined
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const object: {
    formCount: number;
    multipleForm: boolean;
  } = {
    formCount: travelling ? 1 : 0,
    multipleForm: false,
  };
  if (travellerType) {
    if (travellerType === Individual) {
      if (travelling === true) {
        object.formCount = 1;
      }
      if (travelling === false) {
        object.formCount = 0;
      }
    }
    if (travellerType === SingleParentFamily) {
      if (travelling === true) {
        object.formCount = 1;
        object.multipleForm = true;
      }
      if (travelling === false) {
        object.formCount = 2;
      }

      object.multipleForm = true;
    }
    if (travellerType === Family) {
      if (travelling === true) {
        object.formCount = 2;
        object.multipleForm = true;
      }
      if (travelling === false) {
        object.formCount = 3;
      }

      object.multipleForm = true;
    }
    if (travellerType === Group) {
      if (travelling === true) {
        object.formCount = 1;
        object.multipleForm = true;
      }
      if (travelling === false) {
        object.formCount = 2;
      }

      object.multipleForm = true;
    }
    if (travellerType === Couple) {
      if (travelling === true) {
        object.formCount = 1;
        object.multipleForm = true;
      }
      if (travelling === false) {
        object.formCount = 2;
      }

      object.multipleForm = false;
    }
  }
  return object;
}

function calculateAge(birthDate: string) {
  // Get the current date
  const birthDateObj = new Date(birthDate);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = currentDate.getMonth() - birthDateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthDateObj.getDate())
  ) {
    // eslint-disable-next-line no-plusplus
    age--;
  }

  return age;
}

// eslint-disable-next-line consistent-return
export function formatDate(date: Date | undefined) {
  if (date) {
    const dataModified = new Date(date);
    const year = dataModified.getFullYear();
    const month = (dataModified.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
    const day = dataModified.getDate().toString().padStart(2, "0"); // Add leading zero if single digit

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}

export function generateOrganizerAsTraveler(
  tripTravelers: Array<Traveller> | undefined,
  organizer: Organiser | undefined
) {
  const travelers = [];
  let index = 0;
  if (organizer && organizer.travelling) {
    const organiserTravellerId = sessionStorage.getItem("organiserTravellerId");
    const traveler: Traveller = {
      title: organizer.title,
      firstName: organizer.firstName,
      lastName: organizer.lastName,
      nationalityId: organizer?.nationalityId,
      dateOfBirth:
        organizer?.dateOfBirth && formatDate(new Date(organizer?.dateOfBirth)),
      // eslint-disable-next-line no-plusplus
      sequence: ++index,
    };

    if (organizer.dateOfBirth) {
      traveler.age = calculateAge(organizer.dateOfBirth);
    }

    if (organiserTravellerId && organiserTravellerId !== "notapply") {
      traveler.encryptedId = organiserTravellerId;
    }

    travelers.push(traveler);
  }
  if (tripTravelers && tripTravelers.length > 0) {
    const newTravelers = tripTravelers.map((traveler) => {
      return {
        ...traveler,
        // eslint-disable-next-line no-plusplus
        sequence: ++index,
      };
    });
    travelers.push(...newTravelers);
  }
  return travelers;
}

export function generateTravelerDetails(
  array: FormDataType[],
  organiser: Organiser | undefined,
  isOrganizerEdit: boolean
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detailsArray: any[] = [];
  let indexY = 0;
  if (organiser && organiser.travelling && !isOrganizerEdit) {
    const organiserTravellerId = sessionStorage.getItem("organiserTravellerId");
    indexY += 1;

    const traveller: Traveller = {
      title: organiser.title,
      firstName: organiser.firstName,
      lastName: organiser.lastName,
      nationalityId: organiser?.nationalityId,
      dateOfBirth:
        organiser?.dateOfBirth && formatDate(new Date(organiser?.dateOfBirth)),
      sequence: indexY,
    };

    if (organiser.dateOfBirth) {
      traveller.age = calculateAge(organiser.dateOfBirth);
    }

    if (organiserTravellerId && organiserTravellerId !== "notapply") {
      traveller.encryptedId = organiserTravellerId;
    }
    detailsArray.push(traveller);
  }

  if (array && array.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, array-callback-return
    array.map((travellerEntered: any) => {
      indexY += 1;
      const traveller: Traveller = {
        title: travellerEntered.value1,
        firstName: travellerEntered.value2,
        lastName: travellerEntered.value3,
        nationalityId: travellerEntered.nationalityValue,
        dateOfBirth: formatDate(travellerEntered.dateValue),
        sequence: indexY,
        encryptedId: travellerEntered?.encryptedId,
      };
      if (!formatDate(travellerEntered.dateValue) && travellerEntered.value4) {
        traveller.age = Number(travellerEntered.value4);
      }
      if (travellerEntered.fullTimeEducation) {
        // eslint-disable-next-line no-param-reassign
        traveller.fullTimeEducation = true;
      } else {
        traveller.fullTimeEducation = false;
      }

      detailsArray.push(traveller);
    });
  }

  return detailsArray;
}

export function generateFromArray(array: Traveller[], partyType?: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detailsArray: any[] = [];

  // if (organizer) {
  //   const form: FormDataType = {
  //     value1: organizer.title,
  //     value2: organizer.firstName,
  //     value3: organizer.lastName,
  //     id: 1,
  //     dateValue: organizer.dateOfBirth
  //       ? new Date(organizer?.dateOfBirth)
  //       : undefined,
  //     isDateCalenter: true,
  //     value4: "",
  //   };
  //   if (array && array[0]) {
  //     form.value4 = array[0]?.age?.toString() || "";
  //     form.fullTimeEducation =
  //       typeof array[0]?.fullTimeEducation === "boolean"
  //         ? array[0]?.fullTimeEducation
  //         : undefined;
  //     form.encryptedId = array[0]?.encryptedId;
  //   }
  //   detailsArray.push(form);
  // }

  if (array && array.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, array-callback-return
    array.map((travellerEntered: any) => {
      const form: FormDataType = {
        value1: travellerEntered.title,
        value2: travellerEntered.firstName,
        value3: travellerEntered.lastName,
        id: travellerEntered.sequence,
        value4: "",
        dateValue: undefined,
        isDateCalenter: true,
        encryptedId: travellerEntered?.encryptedId,
        nationalityValue: travellerEntered.nationalityId,
      };
      if (travellerEntered.dateOfBirth) {
        // eslint-disable-next-line no-param-reassign
        form.dateValue = new Date(travellerEntered.dateOfBirth);
        form.isDateCalenter = true;
        const birthDate = dayjs(form.dateValue);
        const currentDate = dayjs();
        const age = currentDate.diff(birthDate, "year");
        form.value4 = age.toString();
      } else if (travellerEntered.age) {
        form.value4 = travellerEntered.age;
        form.isDateCalenter = false;
      }
      if (typeof travellerEntered?.fullTimeEducation === "boolean") {
        if (partyType === Family || partyType === SingleParentFamily) {
          form.fullTimeEducation = travellerEntered?.fullTimeEducation;
        } else {
          form.fullTimeEducation = false;
        }
        // eslint-disable-next-line no-param-reassign
      } else {
        form.fullTimeEducation = undefined;
      }

      detailsArray.push(form);
    });
  }

  return detailsArray;
}

const formDataInitialValue = {
  id: 1,
  value1: "",
  value2: "",
  value3: "",
  value4: "",
  dateValue: undefined,
  isDateCalenter: true,
};
function createFormDataArray(length: number) {
  return Array.from({ length }, (_, index) => ({
    ...formDataInitialValue,
    id: index + 1,
  }));
}

export function modifiedExsitingArray(
  array: Traveller[],
  partType: string,
  travelling: boolean | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let detailsArray: any[] = [];
  // if (travelling) {
  //   const mutableArray = array.slice(1);
  //   array = mutableArray;
  // }

  if (array.length === 1) {
    detailsArray.push(array[0]);

    // eslint-disable-next-line default-case
    switch (partType) {
      case Couple:
      case SingleParentFamily:
      case Group:
        if (travelling === false)
          detailsArray = detailsArray.concat(createFormDataArray(1));
        break;
      case Family:
        if (travelling === false) {
          detailsArray = detailsArray.concat(createFormDataArray(2));
        }
        if (travelling === true) {
          detailsArray = detailsArray.concat(createFormDataArray(1));
        }
        break;
    }
  } else if (array.length > 1) {
    if (partType === Individual) {
      if (travelling === false) {
        detailsArray.push(array[0]);
      }
    } else if (partType === Couple) {
      if (travelling === false) {
        detailsArray.push(array[0], array[1]);
      }
      if (travelling === true) {
        detailsArray.push(array[0]);
      }
    } else {
      if (array.length === 2 && partType === Family) {
        detailsArray.push(array[0], array[1]);
        if (travelling === false) {
          detailsArray = detailsArray.concat(createFormDataArray(1));
        }
      } else {
        detailsArray = [...array];
      }
    }
  }

  return detailsArray;
}

export function getDateValidation(
  form: FormDataType,
  index: number,
  travellerType: string,
  travelling: boolean,
  coupleMinAge: string,
  dobMinAge: string,
  dobMaxAge: string,
  ageParentFamily: string,
  ageFamilyFullTimeEducation: string,
  ageGroupUnderTwo: string,
  ageSingleParentFamily: string,
  ageSingleParentFullTimeEducation: string,
  formData?: FormDataType[]
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let errorMessage = "";
  if (form.dateValue && !isValidDate(form.dateValue as Date)) {
    errorMessage = "Invalid Date of Birth";
  } else if (Number(form.value4) < 0) {
    errorMessage = dobMinAge;
  } else if (Number(form.value4) > ageLimitOrganizer.max) {
    errorMessage = dobMaxAge;
  } else if (travellerType === Couple && form.dateValue) {
    if (Number(form.value4) < ageLimitOrganizer.min) {
      errorMessage = coupleMinAge;
    }
  } else if (travellerType === Family && form.dateValue) {
    if (
      ((index === 0 || index === 1) && !travelling) ||
      (travelling && index === 0)
    ) {
      if (Number(form.value4) < ageLimitOrganizer.min) {
        errorMessage = ageParentFamily || "Two person must be above 18";
      } else if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (form.fullTimeEducation || form.fullTimeEducation === undefined) {
          errorMessage =
            ageFamilyFullTimeEducation ||
            "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education";
        }
      }
    } else if ((index > 1 && !travelling) || travelling) {
      if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (
          form.fullTimeEducation === false ||
          form.fullTimeEducation === undefined
        ) {
          errorMessage =
            ageFamilyFullTimeEducation ||
            "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education";
        }
      } else if (Number(form.value4) > 20) {
        errorMessage =
          ageFamilyFullTimeEducation ||
          "A family must be 2 adults plus any number of children under 18, or under 21 if in full time education";
      }
    }
  } else if (travellerType === SingleParentFamily && form.dateValue) {
    if (!travelling && index === 0) {
      if (Number(form.value4) < ageLimitOrganizer.min) {
        errorMessage = ageSingleParentFamily || "One person must be above 18";
      } else if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (form.fullTimeEducation || form.fullTimeEducation === undefined) {
          errorMessage =
            ageSingleParentFullTimeEducation ||
            "Single Parent travel  must be 1 adults plus any number of children under 18, or under 21 if in full time education";
        }
      }
    } else if ((index >= 1 && !travelling) || travelling) {
      if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (
          form.fullTimeEducation === false ||
          form.fullTimeEducation === undefined
        ) {
          errorMessage =
            ageSingleParentFullTimeEducation ||
            "Single Parent travel must be 1 adults plus any number of children under 18, or under 21 if in full time education";
        }
      } else if (Number(form.value4) > 20) {
        errorMessage =
          ageSingleParentFullTimeEducation ||
          "Single Parent travel must be 1 adults plus any number of children under 18, or under 21 if in full time education";
      }
    }
  } else if (travellerType === Group && formData && !travelling) {
    const error1 = getGroupValidate2(formData, index);

    if (error1 && error1.length > 0) {
      errorMessage =
        ageGroupUnderTwo ||
        "Children under 2 must travel with an accompanying adult.";
    }
  }
  return errorMessage;
}

export function getDateValidationStates(
  form: FormDataType,
  index: number,
  travellerType: string,
  travelling: boolean,
  formData?: FormDataType[]
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let errorState = false;
  if (form.dateValue && !isValidDate(form.dateValue as Date)) {
    errorState = true;
  } else if (Number(form.value4) < 0) {
    errorState = true;
  } else if (Number(form.value4) > ageLimitOrganizer.max) {
    errorState = true;
  } else if (travellerType === Couple && form.dateValue) {
    if (Number(form.value4) < ageLimitOrganizer.min) {
      errorState = true;
    }
  } else if (travellerType === Family && form.dateValue) {
    if (
      (!travelling && (index === 0 || index === 1)) ||
      (travelling && index === 0)
    ) {
      if (Number(form.value4) < ageLimitOrganizer.min) {
        errorState = true;
      } else if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (form.fullTimeEducation || form.fullTimeEducation === undefined) {
          errorState = true;
        }
      }
    } else if ((!travelling && index > 1) || travelling) {
      if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (
          form.fullTimeEducation === false ||
          form.fullTimeEducation === undefined
        ) {
          errorState = true;
        }
      } else if (Number(form.value4) > 20) {
        errorState = true;
      }
    }
  } else if (travellerType === SingleParentFamily && form.dateValue) {
    if (!travelling && index === 0) {
      if (Number(form.value4) < ageLimitOrganizer.min) {
        errorState = true;
      } else if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (form.fullTimeEducation || form.fullTimeEducation === undefined) {
          errorState = true;
        }
      }
    } else if ((!travelling && index >= 1) || travelling) {
      if (Number(form.value4) > 17 && Number(form.value4) < 21) {
        if (
          form.fullTimeEducation === false ||
          form.fullTimeEducation === undefined
        ) {
          errorState = true;
        }
      } else if (Number(form.value4) > 20) {
        errorState = true;
      }
    }
  } else if (!travelling && travellerType === Group && formData) {
    const error1 = getGroupValidate2(formData, index);

    if (error1 && error1.length > 0) {
      errorState = true;
    }
  }

  return errorState;
}

const validateAge = (
  form: FormDataType,
  index: number,
  ageErrors: string[],

  travellerType: string,
  dobMinAge: string,
  coupleMinAge: string,
  dobMaxAge: string
) => {
  const errors: string[] = validateDate(
    form,
    travellerType || "",
    dobMinAge || "",
    coupleMinAge || "",
    dobMaxAge || ""
  );
  // eslint-disable-next-line no-param-reassign
  ageErrors[index] = errors.join(". ");

  return ageErrors;
};

const validateGroupData = (
  newFormData: FormDataType[],
  form: FormDataType,
  ageError: string[],
  ageGroupUnderTwo: string,
  dobMinAge: string,
  dobMaxAge: string
) => {
  const errors: string[] = getGroupValidate(
    newFormData,
    form,
    ageGroupUnderTwo || ""
  );

  const errors2: string[] = validateDateGroup(
    form,
    newFormData,
    dobMinAge || "",
    dobMaxAge || ""
  );

  if (errors2 && errors2.length > 0) {
    // eslint-disable-next-line array-callback-return
    errors2.map((error, indexCheck) => {
      if (error) {
        ageError[indexCheck] = error;
      } else {
        ageError[indexCheck] = "";
      }
    });
  }

  if (errors && errors.length > 0) {
    // eslint-disable-next-line array-callback-return
    errors.map((error, indexCheck) => {
      if (error) {
        ageError[indexCheck] = error;
      } else {
        ageError[indexCheck] = "";
      }
    });
  }

  if (errors2.length === 0 && errors.length === 0) {
    ageError = [];
  }
  return ageError;
};

const validateFamilyData = (
  form: FormDataType,
  index: number,
  travellerType: string,
  travelling: boolean,
  ageError: string[],
  ageParentFamily: string,
  ageFamilyFullTimeEducation: string,
  dobMinAge: string,
  coupleMinAge: string,
  dobMaxAge: string
) => {
  let errors: string[] = isFamilyValidate(
    index,
    form,
    travelling,
    ageParentFamily,
    ageFamilyFullTimeEducation
  );
  const errors2: string[] = validateDate(
    form,
    travellerType || "",
    dobMinAge || "",
    coupleMinAge || "",
    dobMaxAge || ""
  );
  errors = errors.concat(errors2);

  if (errors && errors.length > 0) {
    ageError[index] = errors.join(". ");
  } else {
    ageError[index] = "";
  }

  return ageError;
};

const validateSingleFamilyData = (
  form: FormDataType,
  index: number,
  ageError: string[],
  travellerType: string,
  travelling: boolean,
  ageSingleParentFamily: string,
  ageSingleParentFullTimeEducation: string,
  dobMinAge: string,
  coupleMinAge: string,
  dobMaxAge: string
) => {
  let errors: string[] = isSingleFamilyValidate(
    index,
    form,
    travelling || false,
    ageSingleParentFamily,
    ageSingleParentFullTimeEducation
  );
  const errors2: string[] = validateDate(
    form,
    travellerType || "",
    dobMinAge || "",
    coupleMinAge || "",
    dobMaxAge || ""
  );
  errors = errors.concat(errors2);

  if (errors && errors.length > 0) {
    ageError[index] = errors.join(". ");
  } else {
    ageError[index] = "";
  }
  return ageError;
};

export const validateFrom = (
  formDataValidate: FormDataType[],
  travellerType: string,
  travelling: boolean | undefined,
  inputOneErrorNumericvalues: string,
  inputOneErrorSpecialCharacters: string,
  inputOneErrorMaximumNumber: string,
  inputTwoErrorNumericvalues: string,
  inputTwoErrorSpecialCharacters: string,
  inputTwoErrorMaximumNumber: string,
  ageGroupUnderTwo: string,
  dobMinAge: string,
  dobMaxAge: string,
  coupleMinAge: string,
  ageSingleParentFamily: string,
  ageSingleParentFullTimeEducation: string,
  ageParentFamily: string,
  ageFamilyFullTimeEducation: string
) => {
  // eslint-disable-next-line no-plusplus
  const firstNameErrors: string[] = [];
  const lastNameErrors: string[] = [];
  let ageErrors: string[] = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < formDataValidate.length; index++) {
    const data = formDataValidate[index];
    if (data.value1) {
      const errors: string[] = validateName(
        data.value2,
        inputOneErrorNumericvalues || "",
        inputOneErrorSpecialCharacters || "",
        inputOneErrorMaximumNumber || ""
      );

      firstNameErrors[index] = errors.join(". ");
    }

    if (data.value3) {
      const errors: string[] = validateName(
        data.value3,
        inputTwoErrorNumericvalues || "",
        inputTwoErrorSpecialCharacters || "",
        inputTwoErrorMaximumNumber || ""
      );
      lastNameErrors[index] = errors.join(". ");
    }
    if (data.dateValue) {
      if (travellerType === Group && travelling === false) {
        ageErrors = validateGroupData(
          formDataValidate,
          data,
          ageErrors,
          ageGroupUnderTwo,
          dobMinAge,
          dobMaxAge
        );
      } else {
        ageErrors = validateAge(
          data,
          index,
          ageErrors,
          travellerType,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      }
    }
    if (data.value4 && (travelling === true || travelling === false)) {
      if (travellerType === Family) {
        ageErrors = validateFamilyData(
          data,
          index,
          travellerType,
          travelling,
          ageErrors,
          ageParentFamily,
          ageFamilyFullTimeEducation,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      } else if (travellerType === SingleParentFamily) {
        ageErrors = validateSingleFamilyData(
          data,
          index,
          ageErrors,
          travellerType,
          travelling,
          ageSingleParentFamily,
          ageSingleParentFullTimeEducation,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      } else if (travellerType === Group) {
        ageErrors = validateGroupData(
          formDataValidate,
          data,
          ageErrors,
          ageGroupUnderTwo,
          dobMinAge,
          dobMaxAge
        );
      } else {
        ageErrors = validateAge(
          data,
          index,
          ageErrors,
          travellerType,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      }
    }
    if (
      data.fullTimeEducation &&
      (travelling === true || travelling === false)
    ) {
      if (travellerType === Family) {
        ageErrors = validateFamilyData(
          data,
          index,
          travellerType,
          travelling,
          ageErrors,
          ageParentFamily,
          ageFamilyFullTimeEducation,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      } else if (travellerType === SingleParentFamily) {
        ageErrors = validateSingleFamilyData(
          data,
          index,
          ageErrors,
          travellerType,
          travelling,
          ageSingleParentFamily,
          ageSingleParentFullTimeEducation,
          dobMinAge,
          coupleMinAge,
          dobMaxAge
        );
      }
    }
  }
  return {
    firstNameErrors,
    lastNameErrors,
    ageErrors,
  };
};

export function getSelectedSupportContent(
  answerCompleted: boolean | undefined,
  props: TravellersContent,
  organiserTravelling: boolean | undefined,
  hasMedicalCondition?: boolean | undefined
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };

  if (organiserTravelling && !answerCompleted) {
    supportSectionContent = {
      drawerContent: props.defaultSupportDrawer,
      drawerEnable: props.travellingYesLoadMoreEnable,
      rightContent: props.assistantDescription,
      loadMoreButtonText: props.travellingYesLoadMoreButtonText,
    };
  }
  if (!organiserTravelling && !answerCompleted) {
    supportSectionContent = {
      drawerContent: props.defaultSupportDrawer,
      drawerEnable: props.travelliongNoLoadMoreEnable,
      rightContent: props.assistantTravellingNoDescription,
      loadMoreButtonText: props.travelliongNoLoadMoreButtonText,
    };
  }
  const organiserTravelSetAndFormOk: boolean = !!(
    (organiserTravelling === true || organiserTravelling === false) &&
    answerCompleted
  );
  if (MEDICAL_FLOW_V2_ENABLED !== "true") {
    if (organiserTravelSetAndFormOk) {
      supportSectionContent = {
        drawerContent: props.allFieldCompletedSupportDrawer,
        drawerEnable: props.allFieldCompletedLoadMoreEnable,
        rightContent: props.allFieldCompletedDescription,
        loadMoreButtonText: props.allFieldCompletedMoreButtonText,
      };
    }
  }
  if (MEDICAL_FLOW_V2_ENABLED === "true") {
    if (organiserTravelSetAndFormOk) {
      if (hasMedicalCondition === undefined) {
        supportSectionContent = {
          drawerContent: props.allFieldCompletedSupportDrawer,
          drawerEnable: props.allFieldCompletedLoadMoreEnable,
          rightContent: props.allFieldCompletedDescription,
          loadMoreButtonText: props.allFieldCompletedMoreButtonText,
        };
      }
      if (hasMedicalCondition === true) {
        supportSectionContent = {
          drawerContent: props.withMedicalConditionSupportDrawer,
          drawerEnable: props.withMedicalConditionLoadMoreEnable,
          rightContent: props.withMedicalConditionSupportSection,
          loadMoreButtonText: props.withMedicalConditionLoadMoreButtonText,
        };
      }
      if (hasMedicalCondition === false) {
        supportSectionContent = {
          drawerContent: props.withoutMedicalConditionSupportDrawer,
          drawerEnable: props.withoutMedicalConditionLoadMoreEnable,
          rightContent: props.withoutMedicalConditionSupportSection,
          loadMoreButtonText: props.withoutMedicalConditionLoadMoreButtonText,
        };
      }
    }
  }

  return supportSectionContent;
}

// return the role of the traveller based on the type of traveller
export function getTravellerRole(
  organizerData: Organiser | undefined,
  index: number,
  typeOfTraveller: string | undefined
) {
  if (typeOfTraveller === Family) {
    if (organizerData?.travelling) {
      return index === 0 ? "Parent" : "Child";
    }
    return index <= 1 ? "Parent" : "Child";
  }

  if (typeOfTraveller === SingleParentFamily) {
    if (organizerData?.travelling) {
      return "Child";
    }
    return index > 0 ? "Child" : "Parent";
  }

  return null;
}

// get travellr from child and parent label styles according to the brand
const fontConfig = [
  {
    brand: "staysure",
    fontWeightClass: "font-normal",
    leading: "leading-[21px]",
  },
  {
    brand: "avanti",
    fontWeightClass: "font-bold",
    leading: "leading-[23px]",
  },
];

export function getFontClasses(
  lBrand: string | undefined,
  isDesktop: boolean
): string {
  let fontClasses = "";
  fontConfig.forEach((config) => {
    if (config.brand === lBrand) {
      fontClasses = `${isDesktop && lBrand === "staysure" ? "leading-[30px]" : config.leading} ${config.fontWeightClass}`;
    }
  });
  return fontClasses;
}
