import { AnnualMultiTrip, NotSure, SingleTrip } from "@/config/app-constant";
import { DateTypeContent } from "./schema/travel-date-page-schema.utils";
import dayjs from "dayjs";
import { SECONDARY_PRODUCT, PRODUCT } from "@/constants";

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

export const answersQuestionFour = [
  {
    id: 1,
    label: "Trip_Start_Date",
  },
  {
    id: 2,
    label: "Trip_End_Date",
  },
];

export function findAnswerQFourById(id: number) {
  return answersQuestionFour.find((answer) => answer.id === id);
}

export function isValidDate(date: Date) {
  // Parse the string into a Date object
  const dateObject = new Date(date);

  // Extract the year from the date object
  const year = dateObject.getFullYear();

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(dateObject.getTime())) {
    return false;
  }

  // Check if the year is within the valid range (0001 - 9999)
  if (year < 1000 || year > 9999) {
    return false;
  }

  // If the date passes the test, it's valid
  return true;
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if single digit

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function setAMTEndDate(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if single digit

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function checkCurentLocation(fromLocation: string, toLocation: string) {
  let sameLocation = false;

  if (toLocation) {
    if (fromLocation === toLocation) {
      sameLocation = true;
    } else if (
      (toLocation === "GB" ||
        toLocation === "JE" ||
        toLocation === "IM" ||
        toLocation === "GG") &&
      brand !== "expat"
    ) {
      sameLocation = true;
    } else if (
      fromLocation === "ES" &&
      (toLocation === "ES-CN" || toLocation === "ES-IB" || toLocation === "ESP")
    ) {
      sameLocation = true;
    }
  }
  return sameLocation;
}

export function returnCalculateDate(
  date: Date,
  year: number | null,
  month: number | null,
  day: number | null
) {
  const newDate = new Date(date);

  if (year !== null) {
    newDate.setFullYear(newDate.getFullYear() + year);
  }

  if (month !== null) {
    newDate.setMonth(newDate.getMonth() + month);
  }

  if (day !== null) {
    newDate.setDate(newDate.getDate() + day);
  }

  return newDate;
}

export function stripTimeFromDate(date: Date) {
  // Copy the date to avoid modifying the original date object
  const strippedDate = new Date(date);
  strippedDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
  return strippedDate;
}

export function isSameDate(date: Date) {
  const today = new Date();
  // today.setHours(0, 0, 0, 0);
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

// export function isEnteredDateValid(date: Date): boolean {
//   const day = date.getDate();
//   const month = date.getMonth() + 1; // Months are zero-indexed
//   const year = date.getFullYear();

//   // Check if the month is valid (1 to 12)
//   if (month < 1 || month > 12) {
//     return false;
//   }

//   // Check if the day is valid for the given month and year
//   const lastDayOfMonth = new Date(year, month, 0).getDate();
//   if (day < 1 || day > lastDayOfMonth) {
//     return false;
//   }

//   if (
//     month === 2 &&
//     day === 29 && // February 29th
//     (!(year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) // Check for leap year
//   ) {
//     return false;
//   }

//   if (
//     (month === 2 && day > 28) || // February, non-leap year
//     ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) // April, June, September, November
//   ) {
//     return false;
//   }

//   return true;
// }

export function getSelectedSupportContent(
  startDateContent: string | number | undefined,
  props: DateTypeContent,
  policyType: string
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };

  if (policyType === AnnualMultiTrip && !startDateContent) {
    supportSectionContent = {
      drawerContent: props.amtDefaultSupportDrawer,
      drawerEnable: props.amtLoadMoreButton,
      rightContent: props.amtAssistantDescription,
      loadMoreButtonText: props.amtLoadMoreButtonText,
    };
  }
  if (
    (policyType === SingleTrip || policyType === NotSure) &&
    !startDateContent
  ) {
    supportSectionContent = {
      drawerContent: props.stDefaultSupportDrawer,
      drawerEnable: props.stLoadMoreButton,
      rightContent: props.stAssistantDescription,
      loadMoreButtonText: props.stLoadMoreButtonText,
    };
  }
  if (
    (policyType === SingleTrip || policyType === NotSure) &&
    startDateContent
  ) {
    supportSectionContent = {
      drawerContent: props.dateSelectSTSupportDrawer,
      drawerEnable: props.dateSelectSTLoadMoreButton,
      rightContent: props.dateSelectSTDescription,
      loadMoreButtonText: props.dateSelectSTButtonText,
    };
  }

  if (policyType === AnnualMultiTrip && startDateContent) {
    supportSectionContent = {
      drawerContent: props.dateSelectAMTSupportDrawer,
      drawerEnable: props.dateSelectAMTLoadMoreButton,
      rightContent: props.dateSelectAMTDescription,
      loadMoreButtonText: props.dateSelectAMTButtonText,
    };
  }
  return supportSectionContent;
}

export function calculateAgeFromDates(dateOfBirth: string) {
  const birthDate = dayjs(dateOfBirth);
  const currentDate = dayjs();
  const age = currentDate.diff(birthDate, "year");
  return age;
}

const maxDate = {
  avanti: 550,
  staysure: 550,
  expat: 366,
};

export const maxDateForTraveller = maxDate[brand];
export function calculateDateDifference(startDate: Date) {
  const currentDate = new Date();

  if (Number.isNaN(startDate.getTime())) {
    throw new Error("Invalid start date provided");
  }

  const differenceInDays = Math.floor(
    (startDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
  );
  return differenceInDays;
}
